import React, { useState } from "react";
import { motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import axiosClient from "src/services/axiosclient";
import { useAuth } from "src/context/AuthProvider";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { useMessage } from "src/context/MessageContext";
import orangeCurved from "../../assets/images/orangeCurved.png";
import rainbow from "../../assets/images/rainbow.png";
import rainbow2 from "../../assets/images/rainbow2.png";
import balls from "../../assets/images/balls.png";
import CreditCard from "../common/CreditCard";
import { useDrawer } from "src/context/DrawerContext";
import { useNavigate } from "react-router";

const CommandCard = ({ product, quantity, amount }) => {
  const { isOpen, closeDrawer, title, drawerContent } = useDrawer();
  const [cardTypeSvg, setCardTypeSvg] = useState(null);
  const [prouveDoc, setProuveDoc] = useState(null);
  const navigate = useNavigate();

  const { t } = useTranslation("command"); // Initialize translation
  const [rib, setRib] = useState("");
  const { user, updateUserSolde } = useAuth();
  const isRibInvalid = true;
  const isProductNotProduit = product?.article?.type !== "produit";
  const [isCardNumberEmpty, setIsCardNumberEmpty] = useState(false);
  const [isFileEmpty, setIsFileEmpty] = useState(false);
  const [isCardHolderNameEmpty, setIsCardHolderNameEmpty] = useState(false);

  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cardHolder, setCardHolder] = useState("");

  const shakeAnimation = {
    shake: {
      x: [0, -10, 10, -10, 10, 0],
      transition: { duration: 0.5 },
    },
    noShake: {
      x: 0,
    },
  };

  // Handle input changes
  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  const handleCardHolderChange = (e) => {
    setCardHolder(e.target.value);
  };

  const price = product?.article?.productInfo?.price || 0;
  const solde = user?.agenceInfo?.solde || user?.businessFinderInfo?.solde;

  const { addMessage } = useMessage();
  const mutation = useMutation({
    mutationFn: async () => {
      // Validate required fields
      if (product?.article?.type === "virement") {
        if (!cardNumber) {
          setIsCardNumberEmpty(true);
          setTimeout(() => {
            setIsCardNumberEmpty(false);
          }, 500);
        }
        if (!cardHolder) {
          setIsCardHolderNameEmpty(true);
          setTimeout(() => {
            setIsCardHolderNameEmpty(false);
          }, 500);
        }

        console.log("prouveDoc", prouveDoc);

        if (!prouveDoc) {
          console.log("prouveDoc", isFileEmpty);
          setIsFileEmpty(true);
          setTimeout(() => {
            setIsFileEmpty(false);
          }, 500);
        }
        if (!cardHolder || !cardNumber) {
          throw new Error("Inputs are required");
        }
      }

      // Prepare FormData for file upload
      const formData = new FormData();

      // Log card number and holder before appending
      if (product?.article?.type === "virement") {
        // Append fields to FormData
        formData.append("ribNumber", cardNumber);
        formData.append("name", cardHolder);

        if (prouveDoc) {
          formData.append("file", prouveDoc); // Attach the file to the formData
        }
      }
      formData.append("articleId", product?.article?._id || "");
      formData.append("quantity", quantity || 1);
      formData.append(
        "type",
        product?.article?.type === "produit" ? "article" : "virement"
      );
      formData.append(
        "price",
        product?.article?.type === "produit"
          ? product?.article?.productInfo?.price
          : amount
      );

      // Debugging: Log the FormData entries
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      // Send the request with formData
      return axiosClient.post("/marketPlace/commande/", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      });
    },
    onSuccess: (data) => {
      addMessage(t("success.purchase"), "success");
      closeDrawer();
      navigate("/dashboard");
      if (data?.data?.solde) {
        updateUserSolde(data?.data?.ribStatus, data?.data?.solde);
      }
    },
    onError: (error) => {
      console.log("errors", error);
    },
  });

  const handleCardTypeChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setProuveDoc(file);
    }
  };

  const handlePurchase = () => {
    mutation.mutate();
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.1, // Start animating children after a short delay
        staggerChildren: 0.05, // Stagger each child’s animation by 0.3 seconds
      },
    },
  };

  const childVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        ease: "easeOut",
      },
    },
  };
  return (
    <div
      className="fixed h-screen p-4 flex justify-center z-50   rtl:left-0 ltr:right-0
          w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/4"
    >
      <motion.div
        className="w-full h-full grid grid-rows-3 gap-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <motion.div
          className="relative bg-white dark:bg-neutral-800 flex justify-center items-center   rounded-3xl text-center"
          variants={childVariants}
          style={{
            backgroundImage: `url(${rainbow2})`,
            backgroundPosition: "0% 158%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "50%",
          }}
        >
          <label className=" top-3 right-3  absolute  bg-neutral-900  dark:bg-white text-white dark:text-neutral-900 placeholder-neutral-900 px-2  font-semibold  pb-0.5 rounded-full">
            {t("product.totalPrice")}
          </label>

          <p className="text-neutral-900 placeholder-neutral-900  text-5xl font-bold dark:text-neutral-200">
            {amount || price * quantity || 0} TND
          </p>
        </motion.div>
        {/* First row with 2 columns */}
        <div className="grid grid-cols-2     gap-x-4">
          <motion.div
            className="relative bg-white dark:bg-neutral-800 p-4 flex justify-center items-center rounded-3xl text-center aspect-square"
            variants={childVariants}
            style={{
              backgroundImage: `url(${rainbow})`,
              backgroundPosition: "100% 140%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "70%",
            }}
          >
            <label className=" top-3 left-3  absolute  bg-neutral-900  dark:bg-white text-white dark:text-neutral-900 placeholder-neutral-900 px-2  font-semibold  pb-0.5 rounded-full">
              {t("product.qty")}
            </label>

            <p className="text-neutral-900 placeholder-neutral-900 dark:text-neutral-200   text-3xl font-bold">
              {quantity || 0}
            </p>
          </motion.div>
          <motion.div
            className="relative bg-white dark:bg-neutral-800  overflow-hidden p-4 flex justify-center items-center rounded-3xl text-center aspect-square"
            variants={childVariants}
            style={{
              backgroundImage: `url(${orangeCurved})`,
              backgroundPosition: "0% -0%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
            }}
          >
            <label className=" top-3 left-3  absolute  bg-neutral-900   text-orange-300 px-2  font-semibold pb-0.5 rounded-full">
              {t("product.price")}
            </label>

            <p className="text-neutral-900 placeholder-neutral-900  text-3xl font-bold ">
              {solde || 0}
            </p>
          </motion.div>
        </div>

        {product?.article?.type === "virement" && (
          <motion.div
            className="relative bg-white dark:bg-neutral-800 flex flex-col justify-between p-4 rounded-3xl shadow-lg"
            variants={childVariants}
          >
            {/* Credit Card Header (Card Type & Chip) */}
            <div className="flex justify-between items-center">
              <div className="text-sm text-neutral-900 placeholder-neutral-900 dark:text-white dark:placeholder-white ">
                {t("product.label")}
              </div>
              <div className="flex absolute top-3 ltr:right-3 rtl:left-8 justify-between -space-x-5 items-center">
                <div className="w-10 aspect-square rounded-full bg-red-500"></div>
                <div className="w-10 aspect-square rounded-full bg-yellow-500/80"></div>
              </div>
            </div>

            {/* Credit Card Number Input */}
            <motion.div
              animate={isCardNumberEmpty ? "shake" : "noShake"}
              variants={shakeAnimation}
              className="flex justify-center items-center mt-4"
            >
              <input
                type="text"
                placeholder="1234 5678 9012 3456"
                className="w-full text-center bg-transparent text-xl font-semibold tracking-wider text-neutral-900 placeholder-neutral-900 dark:text-white dark:placeholder-white outline-none"
                maxLength={19}
                onChange={handleCardNumberChange}
              />
            </motion.div>

            {/* Card Holder and Expiry */}
            <div className="flex justify-between items-center mt-4">
              <div className="flex flex-col">
                <label className="text-xs text-neutral-900 placeholder-neutral-900 dark:text-white dark:placeholder-white">
                  {t("product.cardHolder")}
                </label>
                <motion.input
                  animate={isCardHolderNameEmpty ? "shake" : "noShake"}
                  variants={shakeAnimation}
                  type="text"
                  placeholder={t("product.placeholder")}
                  className="bg-transparent text-sm font-medium text-neutral-900 placeholder-neutral-900 dark:text-white dark:placeholder-white outline-none"
                  onChange={handleCardHolderChange}
                />
              </div>
            </div>

            {/* SVG as Input for Card Type (Visa/Mastercard) */}
            <div className="absolute ltr:right-4 rtl:left-8 bottom-2">
              <input
                id="card-type"
                type="file"
                style={{ display: "none" }} // Hides the file input
                onChange={handleCardTypeChange} // Handle file input change here
              />

              <motion.div
                animate={isFileEmpty ? "shake" : "noShake"}
                variants={shakeAnimation}
                className="cursor-pointer"
                onClick={() => document.getElementById("card-type").click()} // Trigger input click
              >
                {prouveDoc ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className=" dark:text-white text-neutral-900 size-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className=" dark:text-white text-neutral-900 size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                    />
                  </svg>
                )}
              </motion.div>
            </div>
          </motion.div>
        )}

        <motion.button
          className="bg-white  transition-all hover:scale-125 dark:bg-neutral-800 p-8   flex mt-auto     rounded-3xl"
          onClick={handlePurchase}
          variants={childVariants}
          style={{
            backgroundImage: `url(${rainbow})`,
            backgroundPosition: "250% -1%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "82%",
          }}
        >
          <p className="text-neutral-900  text-xl dark:text-white font-semibold mr-auto">
            Confirmation
          </p>
        </motion.button>
      </motion.div>
    </div>
  );
};

export default CommandCard;
