import React, { useEffect, useState, useMemo } from "react";
import UserAvatar from "../common/UserAvatar";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDrawer } from "src/context/DrawerContext";
import FavoriteItemsList from "../common/FavoriteItemsList";
import axiosClient from "src/services/axiosclient";
import { t } from "i18next";

// SVG Icons
const MarketIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={`transition-all duration-300 ${isActive ? "size-9" : "size-8"}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z"
    />
  </svg>
);

const DashboardIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={`transition-all duration-300 ${isActive ? "size-9" : "size-8"}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
    />
  </svg>
);

const FavoritesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="size-8"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
    />
  </svg>
);

const Header = ({ scrolled = false }) => {
  const currentPage = useLocation().pathname.split("/")[1];
  const navigate = useNavigate();
  const { openDrawer } = useDrawer();
  const { i18n } = useTranslation("home");

  const [isAnimating, setIsAnimating] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation("command"); // Initialize translation

  const toggleLanguage = (language) => {
    setIsAnimating(true);
    const newLanguage = i18n.language === "fr" ? "en" : "fr";
    document.documentElement.dir = newLanguage === "ar" ? "rtl" : "ltr";
    console.log(newLanguage);
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };
  useEffect(() => {
    // Set initial direction based on current language
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    console.log("Current Language:", i18n.language);
    console.log("Translation for product name:", t("product.name"));
  }, [i18n.language]);
  const handleOpenDrawer = () => {
    openDrawer({
      content: <FavoriteItemsList></FavoriteItemsList>,
      title: "Favs",
    });
  };
  const fetchSavedItemsAPI = async () => {
    const { data } = await axiosClient.get(
      `/marketPlace/user/getSavedArticles`
    );
    return data;
  };
  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => setIsAnimating(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  const mobileScreen = window.matchMedia("(max-width: 640px)").matches;

  return (
    <header
      className={`absolute top-0 left-0 right-0 backdrop-filter border-neutral-200 p-10 flex justify-between items-center transition-all z-50 duration-300 ${
        scrolled
          ? "px-5 py-5 bg-white/30 dark:bg-neutral-800"
          : "px-5 py-10  border-b-[0px]"
      } text-black dark:text-white backdrop-blur-3xl`}
    >
      <h1 className="text-2xl font-bold">Talentxpo</h1>

      <div className="flex items-center space-x-8">
        <div className="flex items-center space-x-4">
          {!mobileScreen && (
            <>
              <button
                aria-label="Navigate to Market Place"
                onClick={() => navigate("/market-place")}
              >
                <MarketIcon isActive={currentPage === "market-place"} />
              </button>
              <button
                aria-label="Navigate to Dashboard"
                onClick={() => navigate("/dashboard")}
              >
                <DashboardIcon isActive={currentPage === "dashboard"} />
              </button>
              <button
                aria-label="Open Favorites Drawer"
                onClick={handleOpenDrawer}
              >
                <FavoritesIcon />
              </button>
            </>
          )}
        </div>
        <UserAvatar />
      </div>
    </header>
  );
};

export default Header;
