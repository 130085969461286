import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const FilterOptions = ({ onFilterChange }) => {
  const { t } = useTranslation("marketPlace");
  const filters = [
    { key: "All", label: "" },
    { key: "Favorites", label: "Favoris" },
    { key: "New", label: "Nouveauté" },
    { key: "Hobbies", label: "Loisirs" },
    { key: "Associations", label: "Associations" },
    { key: "Transfers", label: "Virements" },
    { key: "Training", label: "Formations" },
  ];

  const { fav } = useParams();
  // Initialize activeFilters to include the "All" filter
  const [activeFilters, setActiveFilters] = useState(
    fav === "fav" ? ["Favoris"] : [""]
  );
  const containerRef = useRef(null);

  const handleFilterClick = (filterKey) => {
    setActiveFilters((prev) => {
      // If "All" is clicked, reset to only include "All"
      if (filterKey === "") {
        return [filterKey];
      }

      // If "All" is active, and another filter is clicked, include the new filter
      if (prev.includes("") && !prev.includes(filterKey)) {
        return [...prev.filter((f) => f !== ""), filterKey];
      }

      // If the filter is already active, remove it
      if (prev.includes(filterKey)) {
        return prev.filter((f) => f !== filterKey);
      } else {
        // Otherwise, add the filter
        return [...prev, filterKey];
      }
    });
  };

  useEffect(() => {
    onFilterChange(activeFilters);
    if (activeFilters.length === 0) setActiveFilters([""]);
  }, [activeFilters]);

  return (
    <div className="relative flex flex-col md:flex-row items-center justify-between py-4 gap-4 w-full">
      <div
        className="flex flex-wrap space-x-4 items-start justify-start w-full md:w-auto"
        ref={containerRef}
      >
        {filters.map((filter) => (
          <p
            key={filter.label}
            onClick={() => handleFilterClick(filter.label)}
            className={`text-sm sm:text-base md:text-lg font-medium inline-block px-3 py-1 rounded-full cursor-pointer transition-transform duration-500 ease-in-out ${
              activeFilters.includes(filter.label)
                ? "text-white  bg-black dark:bg-white dark:text-neutral-950"
                : "text-neutral-950 dark:text-neutral-50 hover:scale-110 bg-transparent"
            }`}
          >
            {t("filters." + filter.key)}
          </p>
        ))}
      </div>
    </div>
  );
};

export default FilterOptions;
