import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import axiosClient from "src/services/axiosclient";
import LoadingPage from "./InfoComponents/LoadingPage";
import QuantityCounter from "./QuantityCounter";
import Slider from "./Slider";
import smartWatch from "src/assets/images/smartWatch.webp";
import HeartOutline from "../../components/common/HeartOutline";
import HeartFilled from "../../components/common/HeartFilled";
import { useState } from "react";
import { useDrawer } from "src/context/DrawerContext";
import { useAuth } from "src/context/AuthProvider";
import CommandCard from "../../components/common/CommandCard";

// Fetch product data
const fetchProduct = async ({ queryKey }) => {
  const [, id] = queryKey;
  const { data } = await axiosClient.get(`marketPlace/article/${id}`);
  return data;
};

const ProductDetailsCard = () => {
  const { t } = useTranslation("productDetails"); // Initialize useTranslation hook

  const { id } = useParams();
  const queryClient = useQueryClient();
  const { openDrawer } = useDrawer();
  const [quantity, setQuantity] = useState(1);
  const [amount, setAmount] = useState(0);
  const { user } = useAuth();
  const solde = user?.agenceInfo?.solde || user?.businessFinderInfo?.solde;

  // Query to fetch product data
  const {
    data: product,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["product", id],
    queryFn: fetchProduct,
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes, data will be considered fresh for this duration
    cacheTime: 30 * 60 * 1000, // 30 minutes, data will remain in cache for this duration
  });

  // Mutation to save product
  const { mutate: saveProduct, isPending: isSaving } = useMutation({
    mutationFn: async (productId) => {
      await axiosClient.patch(
        `/marketPlace/user/updateSavedArticles/${productId}`
      );
    },
    onSuccess: () => {
      // Invalidate and refetch product data to update the saved state
      queryClient.invalidateQueries(["product", id]);
    },
    onError: (error) => {
      console.error(t("error", { message: error.message })); // Use translation for error message
    },
  });

  const handleSaveProduct = () => {
    if (product?.article?._id) {
      saveProduct(product.article._id);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", stiffness: 120, damping: 20 },
    },
    exit: {
      opacity: 0,
      x: 50,
      transition: { type: "spring", stiffness: 120, damping: 20 },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.2, duration: 0.5, ease: "easeInOut" },
    },
    hover: {
      scale: 1.05,
      rotate: 3,
      transition: { type: "spring", stiffness: 300, damping: 20 },
    },
  };

  const handleOpenDrawer = () => {
    openDrawer({
      content: (
        <CommandCard product={product} quantity={quantity} amount={amount} />
      ),
      title: "Confirmation",
    });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <div>{t("error", { message: error.message })}</div>;
  }

  return (
    <motion.div
      className="rounded-lg gap-4 flex flex-col lg:flex-row w-full max-w-5xl backdrop-blur-sm mx-auto p-4 h-auto lg:h-[600px]"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      {/* Image Section */}
      <motion.div
        className="lg:w-3/5 w-full p-4 bg-sky-300/80 backdrop-blur-sm rounded-2xl relative group h-full"
        whileHover="hover"
      >
        <div className="bg-green-400 text-white px-2 py-1 top-2 left-2 rounded-lg absolute transition-transform duration-300">
          {t("new")}
        </div>

        <div className="  text-white font-medium text-2xl px-2 py-1 bottom-2 left-2 rounded-lg absolute transition-transform duration-300">
          {product?.article?.productInfo?.price
            ? product?.article?.productInfo?.price + "TND"
            : null}
        </div>
        <button
          className="bg-white/95 text-neutral-800 p-2 top-2 right-2 rounded-lg absolute transition-transform duration-300 z-50"
          onClick={handleSaveProduct}
        >
          {isSaving ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="animate-spin h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          ) : product?.article?.isSaved ? (
            <HeartFilled />
          ) : (
            <HeartOutline />
          )}
        </button>
        <motion.img
          variants={imageVariants}
          layoutId={`card-image-${id}`}
          src={product?.article?.photo?.fileLink_Atatchement || smartWatch}
          alt={product?.article?.name || t("productNamePlaceholder")}
          className="w-full h-full p-6 object-contain rounded-lg"
          style={{
            filter: "drop-shadow(5px 40px 20px rgba(14, 165, 233, 0.8))",
          }}
        />
      </motion.div>

      {/* Details Section */}
      <motion.div
        className="lg:w-2/5 w-full p-4 gap-y-4 flex flex-col items-start justify-between bg-white/70 dark:bg-neutral-800 backdrop-blur-xl rounded-2xl transition-transform duration-300 h-full"
        variants={containerVariants}
      >
        <h2 className="text-xl dark:text-white lg:text-2xl font-semibold">
          {product?.article?.name || t("productNamePlaceholder")}
        </h2>
        <p className="mb-auto text-sm  dark:text-white lg:text-base">
          {product?.article?.description || t("productDescriptionPlaceholder")}
        </p>

        {solde > 0 ? (
          <>
            {product?.article?.type === "produit" ? (
              <QuantityCounter setQuantityGlobal={setQuantity} />
            ) : (
              <Slider solde={solde} setAmountGolobal={setAmount} />
            )}
            <motion.button
              onClick={handleOpenDrawer}
              className="px-4 py-2 w-full bg-sky-300 text-white font-semibold rounded-lg hover:bg-sky-400"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t("buyNow")}
            </motion.button>
          </>
        ) : (
          <div className="flex flex-col items-center w-full">
            <div className="text-red-600 font-semibold mb-2">
              {t("insufficientBalance")}
            </div>
            <button className="px-4 py-2 bg-neutral-300 text-neutral-700 font-semibold rounded-lg cursor-not-allowed">
              {t("buyNow")}
            </button>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default ProductDetailsCard;
